import axios from "axios";
export function queryPurchaserTaskList(memberUrl,params) {
    return axios.post(memberUrl + "/biz/reportFrom/plat/queryPurchaserTaskList.html", params)
}
export function queryPurchaserTaskListByPage(memberUrl,params) {
    return axios.post(memberUrl + "/biz/reportFrom/plat/queryPurchaserTaskListByPage.html", params)
}
export function querySjdpTaskList(memberUrl,params) {
    return axios.post(memberUrl + "/biz/reportFrom/plat/querySjdpTaskList.html", params)
}
export function querySjdpTaskListByPage(memberUrl,params) {
    return axios.post(memberUrl + "/biz/reportFrom/plat/querySjdpTaskListByPage.html", params)
}
export function queryBuyerBudgetAmount(memberUrl,params) {
    return axios.post(memberUrl + "/biz/reportFrom/plat/queryBuyerBudgetAmount.html", params)
}
export function queryBuyerBudgetAmountByPage(memberUrl,params) {
    return axios.post(memberUrl + "/biz/reportFrom/plat/queryBuyerBudgetAmountByPage.html", params)
}