<template>
  <el-form ref="form" :model="form" style="padding:20px;" class="report-procurement">
    <el-tabs type="border-card">
      <el-tab-pane label="招采总览">
        <platform></platform>
      </el-tab-pane>
      <el-tab-pane label="效益分析">
        <procurement-overview/>
      </el-tab-pane>
      <el-tab-pane label="风险分析">
      </el-tab-pane>
    </el-tabs>
  </el-form>
</template>

<script>
import {nextTick} from 'vue';
import moment from 'moment';
import {queryPurchaserTaskList} from "@/api/report/benefitReports";
import Platform from "@/views/report/platform";
import BenefitReports from "@/views/report/benefitReports";
import ProcurementOverview from "@/views/report/procurementOverview";

export default {
  name: 'Tabs',
  components: {
    ProcurementOverview,
    Platform
  },
  data() {
    return {}
  },
  methods: {}
};
</script>
<style>
.report-procurement .el-tabs__content{
  overflow: auto !important;
  min-width: 1150px !important;
}
</style>
