import $ from "jquery";

export default {
  /**
   * @author hfm
   * 这个表格是基于element所设置的，如果没有用el-table，可直接调用table2excel的方法
   * @param elementHeaderClassName 导出表格的head的class
   * @param elementBodyClassName 导出表格的body的class
   * @param fileName 导出的文件名，
   * @param documentName 文档名称，
   * @param notExportClass 不被导出的列
   */
  table2excel(elementHeaderClassName, elementBodyClassName, fileName, documentName, notExportClass) {
    //重新创建一个table
    let table = $("<table></table>");
    //获取表头，element的head 和body的单独的表格,重新挂载
    let head = $(elementHeaderClassName + " thead");
    //删除这个元素，element-ui自动生成的，不知道干啥用的，删除掉
    head.find(".gutter").remove();
    //body 元素
    let body = $(elementBodyClassName + " tbody");
    //追加子节点
    table.append(head.html());
    table.append(body.html());
    table.table2excel({
      exclude: notExportClass,   //不被导出表格行的class类
      sheetName: undefined,     //文档名称
      filename: fileName,//文件名称
      exclude_img: false, //是否导出图片
      exclude_links: false,//是否导出超链接
      exclude_inputs: false//是否导出input框中的内容
    })
  }
}
