<template>
  <el-form ref="form" :model="form" style="padding:20px;" class="">

    <div class="report-row01-export" v-loading="loading">
      <div style="display:flex; min-height: 190px; margin-bottom: 10px;">
        <el-card class="box-card-cards">
          <div slot="header" class="clearfix" style="display: flex;align-items: center">
            <div style="flex: 1;font-weight: bold;font-size: 18px">采购方概算价大屏报表</div>
            <div style="display: flex;justify-content: right;align-items: center">
              <el-input type="text" style="width: 200px" @input="cgfsjdpbbChange" placeholder="采购方名称" size="mini" v-model="form.agentName"></el-input>
              <el-date-picker size="mini" v-model="placeholderTime" style="width: 230px;margin-left: 10px"
                              value-format="yyyy-MM-dd" format="yyyy-MM-dd"
                              type="monthrange" range-separator="-" start-placeholder="开始日期"
                              @input="cgfsjdpbbChange" end-placeholder="结束日期">
              </el-date-picker>
              <el-button
                  @click="exportExcelDp"
                  size="mini"
                  style="margin-left: 10px"
                  type="primary"
                  icon="el-icon-download">导出
              </el-button>
            </div>
          </div>
          <el-table :data="sjdpListBypage" :stripe="true" :row-class-name="tableRowClassName2">
            <el-table-column label="日期">
                <template slot-scope="scope">
                    <span>{{ scope.row.date }}</span>
                </template>
            </el-table-column>
            <el-table-column label="采购方名称">
              <template slot-scope="scope">
                <span>{{ scope.row.agentName }}</span>
              </template>
            </el-table-column>
            <el-table-column label="项目数量">
              <template slot-scope="scope">
                <span>{{ scope.row.bidNumToday }}</span>
              </template>
            </el-table-column>
            <el-table-column label="撮合交易额(元)">
              <template slot-scope="scope">
                <span>{{ scope.row.amontToday }}</span>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
              v-show="total>0"
              :page-size="pageSize"
              :total="total"
              :current-page="pageNum"
              @current-change="handleDpCurrentChange"
              background
              layout="total, prev, pager, next">
          </el-pagination>
        </el-card>
      </div>
    </div>

  </el-form>
</template>

<script>
import {nextTick} from 'vue';
import moment from 'moment';
import exportExcel from "@/utils/exportExcel";
import {queryBuyerBudgetAmount, queryBuyerBudgetAmountByPage} from "@/api/report/benefitReports";
export default {
  name: 'cgfsjdpReports',
  data() {
    return {
      downFlag: false,
      loading:false,
      form: {
        agentName:"",
      },
      placeholderTime: [],
      sjdpListBypage:[],
      sjdpList:[],
      total: 0,
      pageNum: 0,
      pageSize: 5,
    }
  },
  created() {
    this.getSjdpTaskListByPage();
    this.getSjdpTaskList();
  },
  methods: {
    exportExcelDp() {
      let that = this;
      that.loading = true;
      if (that.downFlag) {
        let fileName = "采购方概算价大屏报表" + moment(new Date()).format("YYYY-MM-DD-HH-mm-ss");
        //创建一个新的表格
        exportExcel.table2excel(
            ".report-export .el-table__header",
            '.report-export .el-table__body', fileName, fileName, "");
        that.loading = false;
        return false;
      }
      setTimeout(function () {
        that.exportExcel()
      }, 1000);
    },


    cgfsjdpbbChange(e) {
      this.getSjdpTaskListByPage(e);
      this.getSjdpTaskList(e);
    },


    getSjdpTaskList() {
      let that = this;
      let query = {};
      query.agentName = this.form.agentName;
      if (this.placeholderTime && this.placeholderTime.length == 2) {
        query.startTime = this.placeholderTime[0];
        query.endTime = this.placeholderTime[1];
      }else {
        query.startTime = null;
        query.endTime = null;
      }
      that.loading = false;
      queryBuyerBudgetAmount(this.member, query).then(res => {
        if (res.status == 200) {
          this.sjdpList = res.data.resultBmList;
          this.downFlag = true;
        }
      })
    },


    getSjdpTaskListByPage() {
      let that = this;
      let query = {};
      query.pageNo = this.pageNum;
      query.pageSize = this.pageSize;
      query.agentName = this.form.agentName;
      if (this.placeholderTime && this.placeholderTime.length == 2) {
        query.startTime = this.placeholderTime[0];
        query.endTime = this.placeholderTime[1];
      }else {
        query.startTime = null;
        query.endTime = null;
      }
      that.loading = true;

      queryBuyerBudgetAmountByPage(this.member, query).then(res => {
        if (res.status == 200) {
          //分页
          this.sjdpListBypage = res.data.resultBmList;
          if( this.sjdpListBypage==0){
            this.total = 0;
          }else{
            this.total = res.data.pageInfo.total;
          }
          that.loading = false;
        }
      })
    },


    handleDpCurrentChange: function (val) {
      this.pageNum = val;
      this.getSjdpTaskListByPage();
    },


    tableRowClassName({row, rowIndex}) {
      if (rowIndex % 2 == 1) {
        return 'warning-row';
      } else {
        return 'success-row';
      }
      return '';
    },


    tableRowClassName2({row, rowIndex}) {
      if (rowIndex % 2 == 1) {
        return 'warning-row';
      } else {
        return 'success-row';
      }
      return '';
    },


    indexMethod(index) {
      return index + 1;
    },


    indexMethod2(index) {
      return index + 1;
    },
  }
}

</script>

<style scoped>
.el-card {
  font-size: 14px;
}

@media screen and (max-width: 1200px) {
  .report-row01 {
    height: 50%;
    min-height: 420px;
    margin-bottom: 10px;
  }

  .report-row01-export {
    height: 50%;
    min-height: 420px;
    margin-bottom: 10px;
  }

  .report-row01-export {
    height: 50%;
    min-height: 420px;
    margin-bottom: 10px;
  }

  .report-row01 {
    height: 50%;
    min-height: 285px;
  }

  .report-row01-line1 {
    margin: 0 auto 10px auto;
    min-width: 910px;
    width: 50%;
  }

  .report-row01-line2,
  .report-row01-line2 {
    margin: 0 auto;
    min-width: 910px !important;
  }

  .report-row01-line1 {
    display: flex;
    min-width: 910px;
    width: 50%;
    margin: 0 auto 10px auto;
  }

  .report-row01-line1-2 {
    min-width: 450px;
    width: 50%;
  }

}

@media (min-width: 1200px) {
  .report-row01 {
    height: 50%;
    min-height: 420px;
    margin-bottom: 10px;
    display: flex;
  }

  .report-row01-export {
    height: 50%;
    min-height: 420px;
    margin-bottom: 10px;
    display: flex;
  }

  .report-row01-export {
    height: 50%;
    min-height: 420px;
    margin-bottom: 10px;
    display: flex;
  }

  .report-row01-line2 {
    /* margin-right: 10px; */
  }

  .report-row01 {
    height: 50%;
    min-height: 285px;
    display: flex;
  }

  .report-row01-line1 {
    margin-right: 10px;
    min-width: 900px;
    width: calc(50% - 10px);
  }

  .report-row01-line1 {
    display: flex;
    min-width: 910px;
    width: calc(50% - 10px);
    margin-right: 10px;
  }

  .report-row01-line1-2 {
    min-width: 440px;
    width: calc(50% - 5px);
  }
}


.procurement-table-body01 >>> .el-card__body,
.el-main {
  width: 100% !important;
}

.procurement-table-body02 >>> .el-card__body,
.el-main {
  width: 100% !important;
}

.report-procurement >>> .el-skeleton {
  background-color: #fff;
  padding: 10px;
  height: 100%;
}

.report-procurement >>> .el-card__body,
.el-main {
  width: 925px;
}

.report-procurement-detail >>> .el-range-editor--mini .el-range-separator {
  z-index: 3;
}

.report-procurement-detail >>> .el-range-editor--mini .el-range__close-icon,
.el-range-editor--mini .el-range__icon,
.report-procurement-detail >>> .el-range-editor--mini .el-range__close-icon,
.el-range-editor--mini .el-range__icon {
  display: none;
}

.report-procurement-detail >>> .el-date-editor--daterange.el-input,
.el-date-editor--daterange.el-input__inner,
.el-date-editor--timerange.el-input,
.el-date-editor--timerange.el-input__inner {
  width: 280px !important;
}

.report-procurement-detail >>> .el-tabs__header {
  margin: 0;
}

.report-procurement-detail >>> .el-tabs__nav-wrap::after {
  display: none;
}

.report-procurement-icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: red;
  display: flex;
  align-items: center;
  justify-content: center;
  /* opacity: 0.5; */
  font-size: 25px;
  color: #fff;
  margin-right: 10px;
}

.report-procurement-table >>> .el-table--fit {
  height: 356px !important;
}

.report-procurement-pro-card {
  display: flex;
  margin-bottom: 5px;
  margin-right: 5px;
  /* padding: 5px 0 5px 5px; */
  align-items: center;
  justify-content: center;
  width: 16%;
  height: 80px;
}

.report-procurement-detail-card {
  /* background-color: #ffe0ff; */
  height: 100px;
  width: 110px;
  margin: 0 10px;
  border-radius: 5px;
}

.report-detail-card-head::after {
  content: '';
  width: 80px;
  height: 1px;
  background-color: #fff;
  position: absolute;
  bottom: 0;
}

.report-detail-card-head {
  background-color: rgb(255 224 255 / 50%);
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0 0 10px 10px;
  position: relative;
}

.report-detail-card-body {
  background-color: rgb(255 224 255 / 50%);
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px 10px 0 0;
}

.report-procurement >>> .el-col-12,
.report-procurement >>> .grid-content,
.report-procurement >>> .el-card {
  height: 100% !important;
  width: 100% !important;
}


</style>

<style>
.el-picker-panel__link-btn{
  /*display: none;*/
}
</style>