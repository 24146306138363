<template>
  <div>
      <BuyerBudgetAmountReports/>
    <benefit-reports/>
    <cgfsjdp-reports/>
  </div>
</template>

<script>
import BenefitReports from "@/views/report/benefitReports";
import CgfsjdpReports from "@/views/report/cgfsjdpReports";
import BuyerBudgetAmountReports from "@/views/report/BuyerBudgetAmountReports.vue";

export default {
  name: "procurementOverview",
  components: {CgfsjdpReports, BenefitReports,BuyerBudgetAmountReports}
}
</script>

<style scoped>

</style>